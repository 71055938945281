import money from "./money";

/*********************************************************************************************
 * @function    : formatCurrencyInputs
 * @purpose     : Formats input fields with the class 'currency-input' to standardize their values.
 *                This function attaches a 'blur' event listener to each input element that processes 
 *                the input value to convert it into a base currency format. 
 *                The conversion handles values with optional units:
 *                - 'K' for thousands
 *                - 'L' for lakhs
 *                - 'CR' for crores
 * @param       : None
 * @returns     : None
 * @version     : 1.0.0
 * @date        : 2024-09-10
 * @license     : All rights reserved. NITE - Hussain Attari
 * @disclaimer  : Some code might have be sourced from online or coded with the help of AI. 
 *                I have then made the necessary changes to adapt it for my specific use.
 *********************************************************************************************/
function formatCurrencyInputs() {
    // Get all input elements with the class 'currency-input'
    const currencyInputs = document.getElementsByClassName('currency-input');

    // Iterate over all elements with the class 'currency-input'
    Array.from(currencyInputs).forEach(input => {
        // Handle keyup event
        input.addEventListener('keyup', () => {
            const inputValue = input.value;

            // Validate the input
            if (!validateInput(inputValue)) {
                alert('Invalid input format. Please use numbers and characters, K, L, or CR only.');
                input.value = '';
                input.focus();
            }
        });


        // Handle blur event
        input.addEventListener('blur', () => {
            const inputValue = input.value;
            const result = convertCurrency(inputValue);
            
            // Update the input value with the converted result
            input.value = result;
        });
    });
}

/**
 * Converts a currency input value to its base currency format.
 * 
 * @param {string} value - The input value to convert.
 * @returns {number|string} The converted value in base currency or the original value if it doesn't match the pattern.
 */
function convertCurrency(value) {
    // Normalize the input: trim, lowercase, and remove all spaces
    const sanitizedValue = value.trim().toUpperCase().replace(/\s+/g, '');

    // Regular expression to match the number and optional unit (K, L, CR)
    const regex = /^(\d+\.?\d*)\s*(L|K|CR)?$/;
    const match = sanitizedValue.match(regex);

    if (match) {
        const numberPart = parseFloat(match[1]);
        const unit = match[2];

        // Conversion multipliers
        switch (unit) {
            case 'CR':
                return numberPart * 10000000; // Crores to base
            case 'L':
                return numberPart * 100000;   // Lakhs to base
            case 'K':
                return numberPart * 1000;     // Thousands to base
            default:
                return numberPart;            // No unit, return as is
        }
    }

    // Return the original value if it doesn't match the pattern
    return value;
}

// Function to validate input characters
function validateInput(value) {
    const regex = /^[0-9KLCR\s.]*$/i; // Allow only numbers, K, L, C, R, spaces, and decimal point
    return regex.test(value);
}

export { formatCurrencyInputs };
/*********************************************************************************************
 * Changelog:
 * 
 * Version 1.0.0 (2024-09-10):
 * - Initial implementation of the formatCurrencyInputs function.
 * - Added functionality to handle input fields with the class 'currency-input'.
 * - Implemented currency conversion logic for units 'K' (thousands), 'L' (lakhs), and 'CR' (crores).
 * - Included normalization of input values to remove spaces and convert to uppercase.
 * - Attached 'blur' event listeners to format and update the input value when the field loses focus.
 * 
 *********************************************************************************************/
